import React, { useEffect, useState } from 'react';
import Adminheader from '../Layout/Adminheader'
import Adminfooter from '../Layout/Adminfooter'
import { useDispatch, useSelector } from 'react-redux';
import { categoryListAction, createCategory } from '../../actions/categoryAction';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { Badge, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';
import { Modal } from "reactstrap";
import Loading from "../Layout/loading/Loading";

//validation schema for category
const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
  SEOdescription: yup.string().required("SEO Description is required"),
  SEOtitle: yup.string().required("SEO Title is required"),
  SEOkeywords: yup.string().required("SEO Keywords is required"),
})

const Category = () => {
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false);
  const toogle = () => setModalOpen(!modalOpen);

  const token = localStorage.getItem("blog-userToken");
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');

  //validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })


  //Add category
  const addCategory = async (data) => {
    dispatch(createCategory(data.name, data.description, data.SEOtitle, data.SEOdescription, data.SEOkeywords))
    navigate('/category')
    setModalOpen(false)
    reset()
  }

  //get category
  const state = useSelector(state => state.categories)
  const { category, resultPerPage, categoryCount,loading } = state;

  //Delete category
  const deleteCategory = async (id) => {
    try {
      handleClose();
      const data = await axios.patch(`${process.env.REACT_APP_BASE_URL}/category/delcategory/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        toast.success('Status Changed');
      }
      dispatch(categoryListAction(keyword, currentPage));
    } catch (err) {
      toast.error(err);
    }
  };

  const setCurrentPageNo = (e) => {
    //eslint-disable-next-line
    setCurrentPage(e);
  };

  const searchSubmitHandler = (e) => {
    e.preventDefault()
    setCurrentPage(1)
    dispatch(categoryListAction(keyword, currentPage
    ));
  }

  //dialoge box
  const [open, setOpen] = React.useState(false);
  const [id, setId] = useState(null)

  const handleClickOpen = (id) => {
    setId(id)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(categoryListAction(keyword, currentPage,15));
  }, [dispatch, keyword, currentPage])

  return (
    <div>
      <div>
        <Adminheader />
        {/* HEADER */}
        <header id="main-header" className="py-2 bg-primary text-white">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2>
                  <i className="fas fa-folder" /> Categories
                </h2>
              </div>
            </div>
          </div>
        </header>
        {/* SEARCH */}
        <div>
          <section id="search" className="py-4 mb-4 bg-light">
            <form onSubmit={searchSubmitHandler}>
              <div className="container">
                <div className="row">
                  <div className="col-md-3 mb-4">
                    <Button
                      className="btn btn-primary btn-block mb-4"
                      color="primary"
                      type="button"
                      onClick={() => setModalOpen(!modalOpen)}
                    >
                      Add Category
                    </Button>
                  </div>
                  <div className="col-md-5 ml-auto mb-4">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Categories..."
                        onChange={(e) => setKeyword(e.target.value)}
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
        {/* CATEGORIES */}
        {loading ? (
          <Loading />
        ) : (<>
          <section id="categories" className="">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="">
                    {categoryCount === 0 ? (
                    <>
                      <p> NO CATEGORY FOUND</p>
                    </>
                    ):(
                    <>
                      <div className="table-responsive">
                      <table className="table table-striped category-table">
                        <thead className="thead-dark">
                          <tr>
                            <th>Name</th>
                            <th>SEO Title</th>
                            <th>SEO Description</th>
                            <th>SEO Keywords</th>
                            <th>Status</th>
                            <th style={{ width: "170px" }}>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {category.map((items) => {
                            return (
                                <tr key={items._id}>
                                  <td>{items.name}</td>
                                  <td>{items.SEOtitle}</td>
                                  <td>{items.SEOdescription}</td>
                                  <td>{items.SEOkeywords}</td>
                                  <td>
                                    {items.isActive === true ? (
                                      <Badge pill bg="primary" text="white">
                                        Active
                                      </Badge>
                                    ) : (
                                      <Badge pill bg="bg-warn" text="white">
                                        InActive
                                      </Badge>
                                    )}
                                  </td>
                                  <td>
                                    <Link
                                      to={`/categoryupdate/${items._id}`}
                                      className="btn border border-success mr-2"
                                    >
                                      <i className="fas fa-angle-double-right" />{" "}
                                      Edit
                                    </Link>

                                    {items.isActive === true ? (
                                      <Button
                                        className="btn btn-warn"
                                        type="button"
                                        onClick={() => handleClickOpen(items._id)}
                                      >
                                        Inactive
                                      </Button>
                                    ) : (
                                      <Button
                                        className="btn btn-success"
                                        type="button"
                                        onClick={() => deleteCategory(items._id)}
                                      >
                                        Activate
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    </>
                    )}
                   
                    {resultPerPage < categoryCount && (
                      <div className="paginationBox">
                        <Pagination
                          activePage={currentPage}
                          itemsCountPerPage={resultPerPage}
                          totalItemsCount={categoryCount}
                          onChange={setCurrentPageNo}
                          nextPageText="Next"
                          prevPageText="Prev"
                          firstPageText="1st"
                          lastPageText="Last"
                          itemClass="page-item"
                          linkClass="page-link"
                          activeClass="pageItemActive"
                          activeLinkClass="pageLinkActive"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>)}

        {/*Dialoge box*/}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete this category?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>NO</Button>
            <Button onClick={() => deleteCategory(id)} autoFocus>
              YES
            </Button>
          </DialogActions>
        </Dialog>

        {/*Add category*/}
        <Modal
          className="modal-body"
          toggle={toogle}
          isOpen={modalOpen}
          backdrop="static"
        >
          <div className="modal-dialog modal-md mt-0">
            <div className="modal-content">
              <div className="modal-header bg-blue text-white">
                <h5 className="modal-title">Add Category</h5>
                <button onClick={() => setModalOpen(false)} className="close" data-dismiss="modal">
                  <span>×</span>
                </button>
              </div>
              <form>

                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          {...register('name')}
                        />
                        <small className="text-danger">
                          {errors.name?.message}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="description"
                          type="text"
                          className="form-control"
                          placeholder="Description"
                          {...register('description')}
                        />
                        <small className="text-danger">
                          {errors.description?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="SEOtitle"
                          type="text"
                          className="form-control"
                          placeholder="SEO title"
                          {...register('SEOtitle')}
                        />
                        <small className="text-danger">
                          {errors.SEOtitle?.message}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="SEOkeywords"
                          type="text"
                          className="form-control"
                          placeholder="SEO keywords"
                          {...register('SEOkeywords')}
                        />
                        <small className="text-danger">
                          {errors.SEOkeywords?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          name="SEOdescription"
                          type="text"
                          className="form-control"
                          placeholder="SEO description"
                          {...register('SEOdescription')}
                        />
                        <small className="text-danger">
                          {errors.SEOdescription?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    {loading ? (
                      <Spinner animation="border" variant="warning" />
                    ):(
                        <button
                        type="button"
                        onClick={handleSubmit(addCategory)}
                        className="btn btn-success"
                        data-dismiss="modal"
                      >
                        Save Category
                      </button>
                    )}
                  
                  </div>
                </div>

              </form>
            </div>
          </div>
        </Modal>
        {/* FOOTER */}
        <Adminfooter />
      </div>
    </div>
  );
}

export default Category