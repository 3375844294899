import React, { useEffect, useState } from 'react';
import Adminheader from '../Layout/Adminheader';
import Adminfooter from '../Layout/Adminfooter';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { singleCategoryAction, updateCategoryAction } from '../../actions/categoryAction';
import { NavLink } from 'react-router-dom'

const Categoryupdate = () => {
  const navigate=useNavigate();
  const { id } = useParams();
  const [detailCategory, setdetailCategory] = useState({
    name: "", description: "", SEOdescription: "", SEOtitle: "", SEOkeywords: ""
  });

  //get single category
  const dispatch = useDispatch();
  const { singlecat } = useSelector((state) => state.singlecategory);

  //update category
  const updateCategory = (e) => {
    e.preventDefault(e)
    dispatch(updateCategoryAction(id, detailCategory.name, detailCategory.description, detailCategory.SEOtitle, detailCategory.SEOdescription, detailCategory.SEOkeywords))
    navigate("/category")
  }

  let name, value;
  const handleInputs = (e) => {

    name = e.target.name;
    value = e.target.value;

    setdetailCategory({ ...detailCategory, [name]: value })
  }

  useEffect(() => {
    if (!singlecat.name || singlecat._id !== id) {
      dispatch(singleCategoryAction(id))
    }
    setdetailCategory(singlecat)
  }, [dispatch, id, singlecat]);

  return (
    <div>
      <div>
        <Adminheader />
        {/* HEADER */}
        <header id="main-header" className="py-2 bg-primary text-white">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="mb-0">Post One</h2>
              </div>
            </div>
          </div>
        </header>
        {/* ACTIONS */}
        <section id="actions" className="py-4 mb-4 bg-light">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <NavLink
                  to="/dashboard"
                  className="btn btn btn-primary btn-block"
                >
                  <i className="fas fa-arrow-left" /> Back To Dashboard
                </NavLink>
              </div>
            </div>
          </div>
        </section>
        {/* DETAILS */}
        <section id="details">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="card">
                  <div className="card-header bg-blue">
                    <h4 className="mb-0 text-white">Edit Category</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="form-group">
                        <label htmlFor="title">Name</label>
                        <input
                          name="name"
                          value={detailCategory.name}
                          onChange={handleInputs}
                          type="text"
                          className="form-control"
                          defaultValue="Post One"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="title">Description</label>
                        <input
                          name="description"
                          value={detailCategory.description}
                          onChange={handleInputs}
                          type="text"
                          className="form-control"
                          defaultValue="Post One"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="title">SEO Title</label>
                        <input
                          name="SEOtitle"
                          value={detailCategory.SEOtitle}
                          onChange={handleInputs}
                          type="text"
                          className="form-control"
                          defaultValue="Post One"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="title">SEO Description</label>
                        <input
                          name="SEOdescription"
                          value={detailCategory.SEOdescription}
                          onChange={handleInputs}
                          type="text"
                          className="form-control"
                          defaultValue="Post One"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="title">SEO Keywords</label>
                        <input
                          name="SEOkeywords"
                          value={detailCategory.SEOkeywords}
                          onChange={handleInputs}
                          type="text"
                          className="form-control"
                          defaultValue="Post One"
                        />
                      </div>
                      <div className="">
                        <button
                          onClick={updateCategory}
                          className="btn btn-success"
                          data-dismiss="modal"
                        >
                          Save Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FOOTER */}
        <Adminfooter />
      </div>
    </div>
  );
}

export default Categoryupdate