import React from 'react'

const Loginheader = () => {
  return (
    <div className="d-flex flex-column">
      <footer id="main-footer" className=" mt-auto bg-dark text-white mt-5 p-4">
          <div className="container">
            <div className="row">
              <div className="col">
                <p className="lead text-center mb-0">
                  Copyright ©
                  <span id="year" />
                  RevanaTech
                </p>
              </div>
            </div>
          </div>
        </footer>
    </div>
    
  )
}

export default Loginheader