import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { loginReducer } from "./reducers/loginReducer";
import { categoryListReducer, categoryUpdateReducer, singleCategoryReducer } from "./reducers/categoryReducer";
import { allPostReducer, singlePostReducer } from "./reducers/postReducer";
import { allResumeReducer } from "./reducers/resumeReducer";

const reducer = combineReducers({
  user: loginReducer,
  categories: categoryListReducer,
  adminposts: allPostReducer,
  singlecategory: singleCategoryReducer,
  categoryUpdate: categoryUpdateReducer,
  singlepost:singlePostReducer,
  resumelist:allResumeReducer
  // deletecategory:categoryDeleteReducer
});

const userInfoFromStorage = localStorage.getItem('blog-userInfo')
  ? JSON.parse(localStorage.getItem('blog-userInfo'))
  : null

const userTokenFromStorage = localStorage.getItem('blog-userToken')
  ? localStorage.getItem('blog-userToken')
  : null

const initialState = {
  user: {
    userInfo: userInfoFromStorage,
    token: userTokenFromStorage
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store;