export const ALL_CATEGORY_REQUEST = "ALL_CATEGORY_REQUEST";
export const ALL_CATEGORY_SUCCESS = "ALL_CATEGORY_SUCCESS";
export const ALL_CATEGORY_FAIL = "ALL_CATEGORY_FAIL";

export const SINGLE_CATEGORY_REQUEST = "SINGLE_CATEGORY_REQUEST";
export const SINGLE_CATEGORY_SUCCESS = "SINGLE_CATEGORY_SUCCESS";
export const SINGLE_CATEGORY_FAIL = "SINGLE_CATEGORY_FAIL";

export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";

export const USER_LOGOUT = 'USER_LOGOUT'
export const CLEAR_ERRORS = "CLEAR_ERRORS";