import React from 'react'

const Loginheader = () => {
  return (
    <div><header id="main-header" className="py-2 bg-primary text-white">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="mb-0">
            <i className="fas fa-user" />Admin</h2>
        </div>
      </div>
    </div>
  </header></div>
  )
}

export default Loginheader