import {
    GET_RESUME_REQUEST,
    GET_RESUME_SUCCESS,
    GET_RESUME_FAIL,
} from "../constants/resumeConstant";

//resume list reducer
export const allResumeReducer = (state={ resume:[]},action)=>{
    switch (action.type){
        case GET_RESUME_REQUEST:
            return{
                loading: true,
                resume:[]
            };
        case GET_RESUME_SUCCESS:
            return{ 
                loading: false,
                resume: action.payload.UserResume, 
                resumeCount: action.payload.resumeCount,
                resultPerPage: action.payload.resultPerPage,
            };
        case GET_RESUME_FAIL:
            return{
                loading: false,
                error: action.payload,  
            };
        default:
            return state;
    }
};