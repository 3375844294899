import React from 'react';
import {Routes,Route} from "react-router-dom";
import Login from './components/Admin/Login';
import Dashboard from './components/Admin/Dashboard';
import Post from './components/Admin/Post';
import Postupdate from './components/Admin/Postupdate';
import Category from './components/Admin/Category';
import Forgotpassword from './components/Admin/Forgotpassword';
import Categoryupdate from './components/Admin/Categoryupdate';
import { ToastContainer } from 'react-toastify';
import Resume from './components/Admin/Resume';
import Changepassword from './components/Admin/Changepassword';
import Errorpage from './components/Admin/Errorpage';

const App = () => {
  return (
    <div> 
    <ToastContainer
          position="bottom-center"
          autoClose={1700}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

    <Routes>
      <Route path="/dashboard" element={<Dashboard/>}/>
      <Route path="/" element={<Login/>}/>
      <Route path="/post" element={<Post/>}/>
      <Route path="/postupdate/:slug" element={<Postupdate/>}/>
      <Route path="/category" element={<Category/>}/>
      <Route path="/categoryupdate/:id" element={<Categoryupdate/>}/>
      <Route path="/forgotpassword" element={<Forgotpassword/>}/>
      <Route path="/resume" element={<Resume/>}/>
      <Route path="/updatepassword" element={<Changepassword/>}/>
      <Route path="*" element={<Errorpage/>}/>
    </Routes> 
   
  {/* <Footer/> */}
  </div>
  )
}

export default App