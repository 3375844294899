import {
    ALL_CATEGORY_REQUEST,
    ALL_CATEGORY_SUCCESS,
    ALL_CATEGORY_FAIL,
    SINGLE_CATEGORY_REQUEST,
    SINGLE_CATEGORY_SUCCESS,
    SINGLE_CATEGORY_FAIL,
    ADD_CATEGORY_REQUEST,
    ADD_CATEGORY_SUCCESS,
    ADD_CATEGORY_FAIL,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAIL,
    CLEAR_ERRORS
} from "../constants/categoryContant";

//category list and add category reducer
export const categoryListReducer = (state={ category:[]},action)=>{
    switch (action.type){
        case ALL_CATEGORY_REQUEST:
            return{
                loading: true,
                category:[]
            };
        case ALL_CATEGORY_SUCCESS:
            return{ 
                loading: false,
                category: action.payload.cate, 
                categoryCount: action.payload.categoryCount,
                resultPerPage: action.payload.resultPerPage,
            };
        case ALL_CATEGORY_FAIL:
            return{
                loading: false,
                error: action.payload,  
            };
            case ADD_CATEGORY_REQUEST:
                return { loading: true,...state}
              case ADD_CATEGORY_SUCCESS:
                return {
                  loading: false,
                  success: true,
                  ...state
                }
              case ADD_CATEGORY_FAIL:
                return {
                  loading: false,
                  error: action.payload,
                  category: [...state.category],
                }
        case CLEAR_ERRORS:
            return{
                ...state,
                error:null,
            };
        default:
            return state;
    }
};

//single category
export const singleCategoryReducer = (state = { singlecat: {} }, action) => {
    switch (action.type) {
      case SINGLE_CATEGORY_REQUEST:
        return {
          loading: true,
          ...state,
        };
      case SINGLE_CATEGORY_SUCCESS:
        return {
          loading: false,
         singlecat: action.payload,
        };
      case SINGLE_CATEGORY_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };

  //update category
  export const categoryUpdateReducer = (state={},action)=>{
    switch (action.type){
        case UPDATE_CATEGORY_REQUEST:
            return{
                ...state,
                loading: true,
            };
        case UPDATE_CATEGORY_SUCCESS:
            return{ 
                ...state,
                loading: false,
                isUpdated: action.payload.updateCategory, 
            };
        case  UPDATE_CATEGORY_FAIL:
            return{
                ...state,
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERRORS:
            return{
                ...state,
                error:null,
            };
        default:
            return state;
    }
};