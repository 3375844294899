import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { logout } from '../../actions/loginAction';
import BrandLogo from '../../assets/brand-logo.png'

const Adminheader = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  //get userInfo
  const user = useSelector(state => state.user)
  const { userInfo } = user

  useEffect(() => {
    if (!userInfo) navigate('/')
    if (userInfo && userInfo.role !== 'admin') navigate('/')
  }, [navigate, userInfo])

  const handleLogout = () => {
    dispatch(logout());
    navigate('/')
    toast.success('logged out')
  };
  return (
    <nav className="navbar navbar-expand-sm navbar-dark p-0">
          <div className="container">
            <NavLink to="/dashboard" className="navbar-brand"><img alt="" src={BrandLogo} /></NavLink>
            <button className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav">
                <li className="nav-item px-2">
                  <NavLink to="/dashboard" className="nav-link">Dashboard</NavLink>
                </li>
                <li className="nav-item px-2">
                  <NavLink to="/post" className="nav-link">Posts</NavLink>
                </li>
                <li className="nav-item px-2">
                  <NavLink to="/category" className="nav-link">Categories</NavLink>
                </li>
                <li className="nav-item px-2">
                  <NavLink to="/resume" className="nav-link">Resume</NavLink>
                </li>
              </ul>
              
              <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown mr-3">
                  <span className="nav-link dropdown-toggle" data-toggle="dropdown">
                    <i className="fas fa-user" /> {userInfo && userInfo.name}
                  </span>
                  <div className="dropdown-menu">
                    <NavLink to="/updatepassword" className="dropdown-item">
                      <i className="fas fa-user-circle"></i> Change Password
                    </NavLink>
                  </div>
                </li>
                <li className="nav-item">
                <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
  )
}

export default Adminheader