import React, { useState } from 'react'
import Adminheader from '../Layout/Adminheader'
import Adminfooter from '../Layout/Adminfooter'
import { Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Changepassword = () => {
  const token = localStorage.getItem("blog-userToken");
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  //update password
  const Updatepassword = async (req, res) => {
    try {
      setLoading(true)
      //eslint-disable-next-line
      const { data } = await axios.patch(`${process.env.REACT_APP_BASE_URL}/user/changepassword`, {
        oldPassword, newPassword, confirmPassword
      }, {
        headers:
        {
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`
        }
      });
      toast.success("Password successfully changed")
      navigate("/dashboard")
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.response.data.error)
    }
  }

  return (
    <div>
      <div>
        <div>
          <Adminheader />
          {/* HEADER */}
          <header id="main-header" className="py-2 bg-success text-white">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h1>
                    <i className="fas fa-folder" /> Change Password</h1>
                </div>
              </div>
            </div>
          </header>

          <Container className='mt-5 mb-5'>
            <Row>
              <Col md={{ span: 3, offset: 3 }}>
                <Card style={{ width: '25rem' }} className="text-center">
                  <Card.Header as="h5">Update Your Password</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <Form
                      >
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label >
                            {/* Current Password */}
                          </Form.Label>
                          <Form.Control
                            name="oldPassword" value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)} type="text"
                            placeholder="Enter Old Password" />

                          <Form.Label >
                            {/* New Password */}
                          </Form.Label>
                          <Form.Control
                            name="newPassword" value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)} type="text"
                            placeholder="Enter New Password" />

                          <Form.Label >
                            {/* Confirm Password */}
                          </Form.Label>
                          <Form.Control
                            name="confirmPassword" value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)} type="text"
                            placeholder="Enter Confirm Password" />
                        </Form.Group>
                        {loading ? (
                          <Spinner animation="border" variant="warning" />
                        ) : (
                          <Button onClick={Updatepassword} variant="primary">Submit</Button>
                        )}

                      </Form>
                    </Card.Text>

                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>

          {/* FOOTER */}
          <Adminfooter />
        </div>
      </div>
    </div>
  )
}

export default Changepassword