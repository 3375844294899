import React, { useEffect, useState } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Adminheader from '../Layout/Adminheader';
import Adminfooter from '../Layout/Adminfooter';
import { useDispatch, useSelector } from 'react-redux';
import { postListAction } from '../../actions/postAction';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import axios from 'axios';
import { Badge, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { categoryListAction } from '../../actions/categoryAction';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';
import { Button, Modal } from "reactstrap";
import Loading from "../Layout/loading/Loading";

//validation schema for blog
const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  SEOdescription: yup.string().required("SEO Description is required"),
  SEOtitle: yup.string().required("SEO Title is required"),
  SEOkeywords: yup.string().required("SEO Keywords is required"),
  category: yup.string().required("Category is required"),
  status: yup.string().required("Status is required")
})

const postStatus = ["Publish", "Draft"]

const Post = ({ ...props }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("blog-userToken");
  const [modalOpen, setModalOpen] = useState(false);
  const toogle = () => setModalOpen(!modalOpen);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setstatus] = useState('');
  const [keyword, setKeyword] = useState('');

  //eslint-disable-next-line
  const [AdminPost, setAdminPost] = useState({
    title: "", category: '', SEOtitle: "", SEOdescription: "", SEOkeywords: "", status: "Draft"
  });
  const [pdescription, setPDescription] = useState('')
  const [file, setFile] = useState('');
  const [Preview, setPreview] = useState('');
  const [loadingPost, setLoading] = useState(false)


  const setCurrentPageNo = (e) => {
    setCurrentPage(e);
  };

  //validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })

  //Add posts
  async function postBlog(data) {
    //eslint-disable-next-line
    const formData = new FormData();
    formData.append('title', data.title)
    formData.append("status", data.status);
    formData.append('description', pdescription)
    formData.append('category', data.category)
    formData.append('SEOtitle', data.SEOtitle)
    formData.append('SEOdescription', data.SEOdescription)
    formData.append('SEOkeywords', data.SEOkeywords)
    formData.append('photo', file)

    const productBody = formData;
    try {
      setLoading(true)
      //eslint-disable-next-line
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/post/create`, productBody, {
        headers:
        {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`
        }
      },
      )
      navigate('/post')
      toast.success("Post Added")
      dispatch(postListAction())
      setModalOpen(false)
      reset()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.response.data.message)
    }
  }

  //text editor
  const handleCkeditorState = (event, editor) => {
    const data = editor.getData();
    setPDescription(data);
  };

  //Delete Post
  const deletePost = async (id) => {
    try {
      handleClose();
      const data = await axios.patch(`${process.env.REACT_APP_BASE_URL}/post/deletepost/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        toast.info('Post delete');
      }
      dispatch(postListAction());
    } catch (err) {
      toast.error(err);
    }
  };

  //dialoge box
  const [open, setOpen] = React.useState(false);
  const [id, setId] = useState(null)

  const handleClickOpen = (id) => {
    setId(id)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //get all posts
  const state = useSelector(state => state.adminposts)
  const { post, postCount, resultPerPage, loading } = state;

  //get all categories
  const states = useSelector(states => states.categories)
  const { category } = states;

  //only active categories can be visible
  const filteredCategory = category.filter((cat) => cat.isActive);

  const searchSubmitHandler = (e) => {
    e.preventDefault()
    setCurrentPage(1)
    dispatch(postListAction(keyword, currentPage,
      status
    ));
  }

  //description image
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("image", file);
            fetch(`${process.env.REACT_APP_BASE_URL}/post/uploadimage`, {
              method: "post",
              body: body,
              headers: {
                Authorization: `Bearer ${token}`,
              }
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: `${process.env.REACT_APP_BASE_URL}/${res.filepath}`
                });
              })

              .catch((err) => {
                reject(err);
              });
          });
        });
      }
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  useEffect(() => {
    if (!file) {
        setPreview(undefined)
        return
    }
    const objectUrl = URL.createObjectURL(file)
    setPreview(objectUrl)
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
}, [file])

//fetching data of blog and category
  useEffect(() => {
    dispatch(postListAction(keyword, currentPage,
      status,15
    ));
    dispatch(categoryListAction("", 1, 1000000));
  }, [dispatch, keyword, currentPage,
    status
  ])

  return (
    <div>
      <div>
        <Adminheader />
        {/* HEADER */}
        <header id="main-header" className="py-2 bg-primary text-white">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1>
                  <i className="fas fa-pencil-alt" /> Posts
                </h1>
              </div>
            </div>
          </div>
        </header>
        {/* SEARCH */}
        <div className="container py-4 mb-4 ">
          <div className="row">
            <div className="col-md-3 mb-4">
              <Button
                className="btn btn-primary btn-block mb-4"
                color="primary"
                type="button"
                onClick={() => setModalOpen(!modalOpen)}
              >
                Add Post
              </Button>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-6 text-right">
              <div className="">
                <div>
                  <section id="search" className="py-1 mb-2 bg-light ">
                    <form onSubmit={searchSubmitHandler}>
                      <div className="">
                        <div className="row">
                          <div className="col-md-3 mb-4">
                            <Form.Select
                              className="
                               post-status  btn btn-primary btn-block"
                              name="status"
                              onChange={(e) => setstatus(e.target.value)}
                            >
                              <option value="Draft">Status</option>
                              {postStatus.map((data) => {
                                return( <option key={data} value={data}>{data}</option>)
                              })}
                            </Form.Select>
                          </div>
                          <div className="col-md-9 text-right mb-4">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search Post..."
                                onChange={(e) => setKeyword(e.target.value)}
                              />
                              <div
                                className="input-group-append"
                              >
                                <button
                                  className=" btn btn-primary"
                                  type="submit"
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </section>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* POSTS */}

        {loading ? (
          <Loading />
        ) : (<>
          <section id="posts">
            <div className="container">
              <div className="row">
                <div className="col">
                  {!postCount ? (
                    <>
                    <p>NO POST FOUND</p>
                    </>
                    ):(
                    <>
                    <div className="table-responsive">
                    <table className="table table-striped category-table">
                      <thead className="thead-dark">
                        <tr>
                          <th>Title</th>
                          <th>Category</th>
                          <th>SEO Title</th>
                          <th>SEO Description</th>
                          <th>SEO Keywords</th>
                          <th>Slug</th>
                          <th>Status</th>
                          <th className="action-btn" style={{ width: "170px" }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {post.map((post) => {
                          return(
                            <tr key={post._id}>
                            <td>{post.title}</td>
                            <td>{post.category.name}</td>
                            <td>{post.SEOtitle}</td>
                            <td>{post.SEOdescription}</td>
                            <td>{post.SEOkeywords}</td>
                            <td>{post.slug}</td>
                            <td>
                              {post.status === "Publish" ? (
                                <Badge pill bg="primary" text="white">
                                  Publish
                                </Badge>
                              ) : post.status === "Draft" ? (
                                <Badge pill bg="bg-warn" text="white">
                                  Draft
                                </Badge>
                              ) : (
                                <Badge pill bg="danger" text="dark">
                                  Deleted
                                </Badge>
                              )}
                            </td>
                            <td>
                              <Link
                                to={`/postupdate/${post.slug}`}
                                className="btn border border-success m-2"
                              >
                                <i className="fas fa-angle-double-right" /> Edit
                              </Link>
                              {post.isActive === true ? (
                                <Button
                                  className="btn btn-danger"
                                  type="button"
                                  onClick={() => handleClickOpen(post._id)}
                                >
                                  Delete
                                </Button>
                              ) : (
                                <Button
                                  className="btn btn-success"
                                  type="button"
                                  onClick={() => deletePost(post._id)}
                                >
                                  Draft
                                </Button>
                              )}
                            </td>
                          </tr>
                          )
                        }
                          
                        )}
                      </tbody>
                    </table>
                    {/* PAGINATION */}
                    {resultPerPage < postCount && (
                      <div className="paginationBox">
                        <Pagination
                          activePage={currentPage}
                          itemsCountPerPage={resultPerPage}
                          totalItemsCount={postCount}
                          onChange={setCurrentPageNo}
                          nextPageText="Next"
                          prevPageText="Prev"
                          firstPageText="1st"
                          lastPageText="Last"
                          itemClass="page-item"
                          linkClass="page-link"
                          activeClass="pageItemActive"
                          activeLinkClass="pageLinkActive"
                        />
                      </div>
                    )}
                  </div>
                    </>
                    )}
                  
                </div>
              </div>
            </div>
          </section>
        </>)}

        {/*Add Post Modal */}
        <Modal
          className="modal-body"
          toggle={toogle}
          isOpen={modalOpen}
          backdrop="static"
        >
          <div className="modal-dialog modal-md mt-0">
            <div className="modal-content">
              <div className="modal-header bg-blue text-white">
                <h5 className="modal-title">Add Post</h5>
                <button onClick={() => setModalOpen(false)} className="close" data-dismiss="modal" >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">

                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          name="title"
                          type="text"
                          className="form-control"
                          placeholder="Title"
                          {...register('title')}
                        />
                        <small className="text-danger">
                          {errors.title?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <Form.Select
                          name="status"
                          className="form-control"
                          {...register("status")}
                        >
                          <option value="">status</option>
                          <option value="Draft">Draft</option>
                          <option value="Publish">Publish</option>
                        </Form.Select>
                        <small className="text-danger">
                          {errors.status?.message}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <Form.Select
                          name="category"
                          className="form-control"
                          defaultValue=""
                          {...register('category')}
                        >
                          <option value=''>Select category</option>
                          {filteredCategory.map((data) => {
                            return (
                              <option key={data._id} value={data._id}>
                                {data.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <small className="text-danger">
                          {errors.category?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="SEOtitle"
                          type="text"
                          className="form-control"
                          placeholder="SEO Title"
                          {...register('SEOtitle')}
                        />
                        <small className="text-danger">
                          {errors.SEOtitle?.message}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="SEOkeywords"
                          className="form-control"
                          placeholder="SEO Keywords"
                          {...register('SEOkeywords')}
                        />
                        <small className="text-danger">
                          {errors.SEOkeywords?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      name="SEOdescription"
                      className="form-control"
                      placeholder="SEO Description"
                      {...register('SEOdescription')}
                    />
                    <small className="text-danger">
                      {errors.SEOdescription?.message}
                    </small>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label htmlFor="image">Upload Image</label>
                        <div className="custom-file mt-2 post-update-img">
                          <input
                          alt={""}
                            name="photo"
                            type="file"
                            onChange={(e) => setFile(e.target.files[0])}
                          />
                         {file &&  <img src={Preview} alt={""}/> }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group m-top">
                    <label htmlFor="body">Description</label>

                    <CKEditor
                      config={{
                        extraPlugins: [uploadPlugin]
                      }}
                      editor={ClassicEditor}
                      onReady={(editor) => { }}
                      onBlur={(event, editor) => { }}
                      onFocus={(event, editor) => { }}
                      onChange={handleCkeditorState}
                      {...props}
                    />


                  </div>
                  <div className="modal-footer">
                  {loadingPost ? (
                      <Spinner animation="border" variant="warning" />
                    ) : (
                    <button
                      type="button"
                      onClick={handleSubmit(postBlog)}
                      className="btn btn-primary"
                      data-dismiss="modal"
                    >
                      Save Post
                    </button>
                     )}
                  </div>
                </form>

              </div>
            </div>
          </div>
        </Modal>

        {/* FOOTER */}
        <Adminfooter />
        {/*Dialoge box*/}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete this post?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>NO</Button>
            <Button onClick={() => deletePost(id)} autoFocus>
              YES
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default Post