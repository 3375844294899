import axios from 'axios';
import React, { useState } from 'react'
import { Button, Card, Form, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loginheader from '../Layout/Loginheader';
import BrandLogo from "../../assets/brand-logo.png";

const Forgotpassword = () => {
  const navigate = useNavigate();
  const [validEmail, setValidEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState({
    userEmail: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  newPassword.userEmail = email;

  //forgot password
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
      await axios.post(`${process.env.REACT_APP_BASE_URL}/user/forgotPassword`, { email })
        .then((res) => {
          setValidEmail(true);
        });
      toast.success("Email Exist")
      setLoading(false)
    } catch (err) {
      setLoading(false)
      if (err.response) {
        setValidEmail(false);
        toast.error(err.response.data.error);
      }
    }
  };

  //reset password
  const handleResetPassword = async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
      if (newPassword.password !== newPassword.confirmPassword) {
        toast.error("confirm password doesn't match");
        return;
      } else {
        await axios
          .put(`${process.env.REACT_APP_BASE_URL}/user/resetPassword`,
            {
              email: newPassword.userEmail,
              password: newPassword.password,
              otp: newPassword.otp,
            }
          )
          .then((res) => {
            setLoading(false)
            toast.success("Password changed successfully");
            navigate("/");
          });
      }
    } catch (err) {
      setLoading(false)
      if (err.response) {
        toast.error(err.response.data.error);
      }
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNewPassword = (e) => {
    setNewPassword({ ...newPassword, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <div>
        <nav className="navbar navbar-expand-sm navbar-dark p-0">
          <div className="container">
            <a href="index.html" className="navbar-brand">
              <img alt="" src={BrandLogo} />
            </a>
          </div>
        </nav>
        {/* HEADER */}
        <Loginheader />
        {/* ACTIONS */}
        <section id="actions" className="py-4 mb-4 bg-light">
          <div className="container">
            <div className="row"></div>
          </div>
        </section>
        {/* LOGIN */}
        <section className="pb-5">
          <div className="container mt-5 pd-bottom">
            <div className="row">
              <div class="col-lg-4 col-md-3 "> &nbsp;</div>
              <div class="col-lg-4 col-md-6">
                <Card className="">
                  <h4 className="mb-0 text-white p-2 bg-blue">
                    Forgot Password?
                  </h4>
                  <Card.Body>
                    <Card.Text>
                      {!validEmail ? (
                        <>
                          <label>Enter Valid Email</label>
                          <Form onSubmit={handleSubmit}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                name="email"
                                value={email}
                                onChange={handleChange}
                                type="email"
                                placeholder="Enter Email"
                              />
                            </Form.Group>
                            {loading ? (
                              <Spinner animation="border" variant="warning" />
                            ) : (
                              <Button type="submit" variant="primary">
                                Submit
                              </Button>)}
                          </Form>
                        </>
                      ) : (
                        <>
                          <h2>Reset Password</h2>
                          <p>
                            <b>
                              Please enter the OTP we've sent you on your
                              registered email address for resetting your
                              password.
                            </b>
                          </p>
                          <Form onSubmit={handleResetPassword}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                {/* Email address */}
                              </Form.Label>
                              <Form.Control
                                name="userEmail"
                                value={newPassword.userEmail}
                                onChange={handleNewPassword}
                                type="email"
                                placeholder="Enter Email"
                              />
                              <Form.Label >
                                {/* OTP */}
                              </Form.Label>
                              <Form.Control name="otp" value={newPassword.otp}
                                onChange={handleNewPassword} type="text" placeholder="Enter OTP" />

                              <Form.Label >
                                {/* New Password */}
                              </Form.Label>
                              <Form.Control name="password" value={newPassword.password}
                                onChange={handleNewPassword} type="text" placeholder="Enter New Password" />

                              <Form.Label >
                                {/* Confirm Password */}
                              </Form.Label>
                              <Form.Control name="confirmPassword" value={newPassword.confirmPassword}
                                onChange={handleNewPassword} type="text" placeholder="Enter Confirm Password" />
                            </Form.Group>

                            {loading ? (
                              <Spinner animation="border" variant="warning" />
                            ) : (
                            <Button type="submit" variant="primary">Submit</Button>)}
                          </Form>
                        </>
                      )
                      }
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div class="col-lg-4 col-md-3"> &nbsp;</div>
            </div>
          </div>
        </section>
        {/* FOOTER */}
        <footer id="main-footer" className="bg-dark text-white p-4">
          <div className="container">
            <div className="row">
              <div className="col">
                <p className="lead text-center mb-0">
                  Copyright ©
                  <span id="year" />
                  RevanaTech
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Forgotpassword
