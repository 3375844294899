import {
    GET_RESUME_REQUEST,
    GET_RESUME_SUCCESS,
    GET_RESUME_FAIL,
} from "../constants/resumeConstant";
import axios from "axios";

 // Get all category
 export const resumeListAction = (keyword='',currentPage=1,from="",to="") => async (dispatch,getState)=>  {
    try{
      dispatch({ type: GET_RESUME_REQUEST });
      const {
        user: { token },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}/resume/getresume?keyword=${keyword}&page=${currentPage}&from=${from}&to=${to}`,config)
      dispatch({ 
          type: GET_RESUME_SUCCESS, 
          payload: data,
        });
    }catch(error){
       dispatch({
        type: GET_RESUME_FAIL,
        payload: error.response.data.message,
       });
    }
};