import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import {useDispatch,useSelector} from "react-redux";
import {login} from '../../actions/loginAction';
import Loginheader from '../Layout/Loginheader';
import BrandLogo from "../../assets/brand-logo.png";
import { Spinner } from 'react-bootstrap';
const Login = () => {
  const navigate=useNavigate();
  const dispatch =useDispatch();

  //admin login 
  const state = useSelector(state => state.user)
  const {userInfo,loading } = state;

  const [password,setPassword]=useState(''); 
  const [email,setEmail]=useState('');

  //admin login
  const adminLogin = async(e)=>{
     dispatch(login(email,password))
  }
  
  useEffect(() => {
    if (userInfo) {
      navigate('/dashboard')
    }
  }, [userInfo, navigate])

  return (
    <div>
      <nav className=" navbar navbar-expand-sm navbar-dark p-0">
        <div className="container">
          <a href="index.html" className="navbar-brand">
            <img alt="" src={BrandLogo} />
          </a>
        </div>
      </nav>
      {/* HEADER */}
      <Loginheader />
      {/* ACTIONS */}
      {/* LOGIN */}
      <section id="login">
        <div className="login-window">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mx-auto">
                <div className="card">
                  <div className="card-header bg-blue">
                    <h4 className="mb-0 text-white">Account Login</h4>
                  </div>
                  <div className="card-body">
                    <form action="index.html">
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          className="form-control"
                        />
                      </div>
                      {loading ? (
                        <Spinner animation="border" variant="warning" />
                      ):(
                        <input
                        type="button"
                        onClick={adminLogin}
                        defaultValue="Login"
                        className="btn btn-primary btn-block"
                      />
                      )}
                    </form>
                      <NavLink to="/forgotpassword">Forgot password?</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* FOOTER */}
      <footer id="main-footer" className="bg-dark text-white p-4">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="lead text-center mb-0">
                Copyright ©
                <span id="year" />
                RevanaTech
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Login