import {
    ALL_CATEGORY_REQUEST,
    ALL_CATEGORY_SUCCESS,
    ALL_CATEGORY_FAIL,
    SINGLE_CATEGORY_REQUEST,
    SINGLE_CATEGORY_SUCCESS,
    SINGLE_CATEGORY_FAIL,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAIL,
    ADD_CATEGORY_REQUEST,
    ADD_CATEGORY_SUCCESS,
    ADD_CATEGORY_FAIL,
} from "../constants/categoryContant";
import axios from "axios";
import { toast } from "react-toastify";

 // Get all category
 export const categoryListAction = (keyword='',currentPage=1,limit) => async (dispatch,getState)=>  {
    try{
      dispatch({ type: ALL_CATEGORY_REQUEST });
      const {
        user: { token },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      let link=`${process.env.REACT_APP_BASE_URL}/category/getcategory?keyword=${keyword}&page=${currentPage}&limit=${limit}`;
      const { data } = await axios.get(link, config);

      dispatch({ 
          type: ALL_CATEGORY_SUCCESS, 
          payload: data,
        });
    }catch(error){
       dispatch({
        type: ALL_CATEGORY_FAIL,
        payload: error.response.data.message,
       });
    }
};

//add category
export const createCategory =
  (name,description,SEOtitle,SEOdescription,SEOkeywords) => async (dispatch, getState) => {
    try {
      dispatch({ type:  ADD_CATEGORY_REQUEST })

      const {
        user: { token },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/category/postcategory`,
        { name,description,SEOtitle,SEOdescription,SEOkeywords},
        config
      )

      dispatch({
        type:  ADD_CATEGORY_SUCCESS,
        payload: data.createdStaff,
      })
      dispatch(categoryListAction());
      toast.success("Category Added")
    } catch (error) {
      dispatch({
        type:  ADD_CATEGORY_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.message,
      })
      toast.error(error.response.data.message)
    }
  };

  //single category by id
  export const singleCategoryAction = (id) => async (dispatch,getState) => {
    try {
      dispatch({ type: SINGLE_CATEGORY_REQUEST });
      const {
        user: { token },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },  
      }
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/category/getcategory/${id}`, config);
  
      dispatch({
        type: SINGLE_CATEGORY_SUCCESS,
        payload: data.category,
      });
      
    } catch (error) {
      dispatch({
        type: SINGLE_CATEGORY_FAIL,
        payload: error.response.data.message,
      });
     
    }
  };

  // Update category
export const updateCategoryAction = (id, name,description,SEOtitle,SEOdescription,SEOkeywords) => async (dispatch,getState) => {
  try {
    dispatch({ type: UPDATE_CATEGORY_REQUEST });
    const {
      user: { token },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },  
    }
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/category/updatecategory/${id}`,
      {name,description,SEOtitle,SEOdescription,SEOkeywords},
      config
    );
    dispatch({
      type: UPDATE_CATEGORY_SUCCESS,
      payload: data,
    });
    toast.success("Category Updated")
  } catch (error) {
    dispatch({
      type: UPDATE_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message)
  }
};





