import React, { useEffect, useState } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Adminheader from "../Layout/Adminheader";
import Adminfooter from "../Layout/Adminfooter";
import { Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  categoryListAction,
  createCategory,
} from "../../actions/categoryAction";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { postListAction } from "../../actions/postAction";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';
import { Button, Modal } from "reactstrap";
import { resumeListAction } from "../../actions/resumeActions";
import { Link } from "react-router-dom";

//validation schema for blog
const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  SEOdescription: yup.string().required("SEO Description is required"),
  SEOtitle: yup.string().required("SEO Title is required"),
  SEOkeywords: yup.string().required("SEO Keywords is required"),
  category: yup.string().required("Category is required"),
  status: yup.string().required("Status is required"),
})

//validation schema for category
const schema1 = yup.object().shape({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
  SEOdescription: yup.string().required("SEO Description is required"),
  SEOtitle: yup.string().required("SEO Title is required"),
  SEOkeywords: yup.string().required("SEO Keywords is required"),
})

const Dashboard = ({ ...props }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const toogle = () => setModalOpen(!modalOpen);

  const [CategorymodalOpen, setCategoryModalOpen] = useState(false);
  const toogles = () => setModalOpen(!CategorymodalOpen);

  const navigate = useNavigate();
  const token = localStorage.getItem("blog-userToken");
  const dispatch = useDispatch();


  //eslint-disable-next-line
  const [AdminPost, setAdminPost] = useState({
    title: "",
    status: "Draft",
    category: "",
    SEOtitle: "",
    SEOdescription: "",
    SEOkeywords: "",
  });
  const [pdescription, setPDescription] = useState("");
  const [file, setFile] = useState('');
  const [Preview, setPreview] = useState('');
  const [loading, setLoading] = useState(false)

  //validation for blog
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  //validation for category
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit1,
  } = useForm({
    resolver: yupResolver(schema1),
  })

  //Add post
  async function postBlog(data) {
    //eslint-disable-next-line
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("status", data.status);
    formData.append("description", pdescription);
    formData.append("category", data.category);
    formData.append("SEOtitle", data.SEOtitle);
    formData.append("SEOdescription", data.SEOdescription);
    formData.append("SEOkeywords", data.SEOkeywords);
    formData.append("photo", file);

    const productBody = formData;
    try {
      setLoading(true)
      //eslint-disable-next-line
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/post/create`,
        productBody,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate('/')
      toast.success("Post Added");
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.response.data.message);
    }
  }

  const handleCkeditorState = (event, editor) => {
    const data = editor.getData();
    setPDescription(data);
  };

  //get userInfo
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  //get all categories
  const state = useSelector((state) => state.categories);
  const { category,categoryCount } = state;

    //get all posts
    const blog = useSelector((state) => state.adminposts);
    const { postCount } = blog;

    //get all posts
    const resume = useSelector((state) => state.resumelist);
    const { resumeCount } = resume;

     //only active categories can be visible
  const filteredCategory = category.filter((cat) => cat.isActive);

  //only admin can access admin dashboard
  useEffect(() => {
    if (!userInfo) navigate("/");
    if (userInfo && userInfo.role !== "admin") navigate("/");
  }, [navigate, userInfo]);

  //eslint-disable-next-line
  const [limit, setLimit] = useState(0);

  useEffect(() => {
    if (!file) {
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(file)
    setPreview(objectUrl)
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [file])

//fetching data of blog,resume and category
  useEffect(() => {
    dispatch(categoryListAction("", 1, 1000000));
    dispatch(postListAction());
    dispatch(resumeListAction());
  }, [dispatch]);

  //add category
  const addCategory = async (data) => {
    dispatch(
      createCategory(data.name, data.description, data.SEOtitle, data.SEOdescription, data.SEOkeywords)
    );
    navigate('/')
  };

  //description image
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("image", file);
            fetch(`${process.env.REACT_APP_BASE_URL}/post/uploadimage`, {
              method: "post",
              body: body,
              headers: {
                Authorization: `Bearer ${token}`,
              }
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: `${process.env.REACT_APP_BASE_URL}/${res.filepath}`
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      }
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div>
      <div>
        <Adminheader />
        {/* HEADER */}
        <header id="main-header" className="py-2 bg-primary text-white">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="mb-0">
                  <i className="fas fa-cog" /> Dashboard
                </h2>
              </div>
            </div>
          </div>
        </header>
        {/* ACTIONS */}
        <section
          id="actions"
          className="py-4 bg-light"
          style={{ height: "65vh" }}
        >
          <div className="container pb-120">
            <div className="row">
              <div className="col-md-3">
                <Button
                  className="btn btn-primary btn-block mb-4"
                  color="primary"
                  type="button"
                  onClick={() => setModalOpen(!modalOpen)}
                >
                  Add Post
                </Button>
                <Button
                  className="btn btn-primary btn-block mb-4"
                  color="primary"
                  type="button"
                  onClick={() => setCategoryModalOpen(!CategorymodalOpen)}
                >
                  Add Category
                </Button>
              </div>
              <div className="col-md-6">&nbsp;</div>
              <div className="col-md-3">
                <div className="card text-center bg-primary text-white mb-3">
                  <div className="card-body">
                    <h3>Blogs</h3>
                    <h4 className="display-4">
                      <i className="fas fa-pencil-alt" /> {postCount}
                    </h4>
                    <Link to="/post" className="btn btn-outline-light btn-sm">
                      View
                    </Link>
                  </div>
                </div>
                <div className="card text-center bg-success text-white mb-3">
                  <div className="card-body">
                    <h3>Categories</h3>
                    <h4 className="display-4">
                      <i className="fas fa-folder" /> {categoryCount}
                    </h4>
                    <Link
                      to="/category"
                      className="btn btn-outline-light btn-sm"
                    >
                      View
                    </Link>
                  </div>
                </div>
                <div className="card text-center bg-warning text-white mb-3">
                  <div className="card-body">
                    <h3>Resume</h3>
                    <h4 className="display-4">
                      <i className="fas fa-users" /> {resumeCount}
                    </h4>
                    <Link to="/resume" className="btn btn-outline-light btn-sm">
                      View
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Adminfooter />
        {/* MODALS */}
        {/* ADD POST MODAL */}
        <Modal
          className="modal-body"
          toggle={toogle}
          isOpen={modalOpen}
          backdrop="static"
        >
          <div className="modal-dialog modal-md mt-0">
            <div className="modal-content">
              <div className="modal-header bg-blue text-white">
                <h5 className="modal-title">Add Post</h5>
                <button
                  onClick={() => setModalOpen(false)}
                  className="close"
                  data-dismiss="modal"
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          name="title"
                          type="text"
                          className="form-control"
                          placeholder="Title"
                          {...register("title")}
                        />
                        <small className="text-danger">
                          {errors.title?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <Form.Select
                          name="status"
                          className="form-control"
                          {...register("status")}
                        >
                          <option value="">status</option>
                          <option value="Draft">Draft</option>
                          <option value="Publish">Publish</option>
                        </Form.Select>
                        <small className="text-danger">
                          {errors.status?.message}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <Form.Select
                          name="category"
                          className="form-control"
                          defaultValue=""
                          {...register("category")}
                        >
                          <option value="">Select category</option>
                          {filteredCategory.map((data) => {
                            return (
                              <option key={data._id} value={data._id}>
                                {data.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <small className="text-danger">
                          {errors.category?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="SEOtitle"
                          type="text"
                          className="form-control"
                          placeholder="SEO Title"
                          {...register("SEOtitle")}
                        />
                        <small className="text-danger">
                          {errors.SEOtitle?.message}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="SEOkeywords"
                          className="form-control"
                          placeholder="SEO Keywords"
                          {...register("SEOkeywords")}
                        />
                        <small className="text-danger">
                          {errors.SEOkeywords?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      name="SEOdescription"
                      className="form-control"
                      placeholder="SEO Description"
                      {...register("SEOdescription")}
                    />
                    <small className="text-danger">
                      {errors.SEOdescription?.message}
                    </small>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label htmlFor="image">Upload Image</label>
                        <div className="custom-file  mt-2 post-update-img">
                          <input
                            name="photo"
                            type="file"
                            onChange={(e) => setFile(e.target.files[0])}
                          />
                          {file && <img src={Preview} alt={""} />}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group m-top">
                    <label htmlFor="body">Description</label>

                    <CKEditor
                      config={{
                        extraPlugins: [uploadPlugin],
                      }}
                      editor={ClassicEditor}
                      onReady={(editor) => {}}
                      onBlur={(event, editor) => {}}
                      onFocus={(event, editor) => {}}
                      onChange={handleCkeditorState}
                      {...props}
                    />
                  </div>
                  <div className="modal-footer">
                    {loading ? (
                      <Spinner animation="border" variant="warning" />
                    ) : (
                      <button
                        type="button"
                        onClick={handleSubmit(postBlog)}
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        Save Post
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>
        {/* ADD CATEGORY MODAL */}
        <Modal
          className="modal-body"
          toggle={toogles}
          isOpen={CategorymodalOpen}
          backdrop="static"
        >
          <div className="modal-dialog modal-md mt-0">
            <div className="modal-content">
              <div className="modal-header bg-blue text-white">
                <h5 className="modal-title">Add Category</h5>
                <button
                  onClick={() => setCategoryModalOpen(false)}
                  className="close"
                  data-dismiss="modal"
                >
                  <span>×</span>
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          {...register2("name")}
                        />
                        <small className="text-danger">
                          {errors2.name?.message}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="description"
                          type="text"
                          className="form-control"
                          placeholder="Description"
                          {...register2("description")}
                        />
                        <small className="text-danger">
                          {errors2.description?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="SEOtitle"
                          type="text"
                          className="form-control"
                          placeholder="SEO title"
                          {...register2("SEOtitle")}
                        />
                        <small className="text-danger">
                          {errors2.SEOtitle?.message}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="SEOkeywords"
                          type="text"
                          className="form-control"
                          placeholder="SEO keywords"
                          {...register2("SEOkeywords")}
                        />
                        <small className="text-danger">
                          {errors2.SEOkeywords?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          name="SEOdescription"
                          type="text"
                          className="form-control"
                          placeholder="SEO description"
                          {...register2("SEOdescription")}
                        />
                        <small className="text-danger">
                          {errors2.SEOdescription?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={handleSubmit1(addCategory)}
                      className="btn btn-success"
                      data-dismiss="modal"
                    >
                      Save Category
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>
        {/* ADD USER MODAL */}
      </div>
    </div>
  );
};

export default Dashboard;
