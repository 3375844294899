import {
    ALL_POST_REQUEST,
    ALL_POST_SUCCESS,
    ALL_POST_FAIL,
    SINGLE_POST_REQUEST,
    SINGLE_POST_SUCCESS,
    SINGLE_POST_FAIL
} from "../constants/postConstant";
import axios from "axios";

 // Get all post
 export const postListAction = (keyword='',currentPage = 1,status,limit) => async (dispatch,getState)=>  {
    try{
      dispatch({ type: ALL_POST_REQUEST });

      const {
        user: { token },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      let link=`${process.env.REACT_APP_BASE_URL}/post/getposts?keyword=${keyword}&page=${currentPage}&limit=${limit}`;

      if(status){
        link=`${process.env.REACT_APP_BASE_URL}/post/getposts?keyword=${keyword}&page=${currentPage}&status=${status}&limit=${limit}`
      }
      
      const { data } = await axios.get(link, config);
      dispatch({ 
          type: ALL_POST_SUCCESS, 
          payload: data,
        });
    }catch(error){
       dispatch({
        type: ALL_POST_FAIL,
        payload: error.response.data.message,
       });
    }
};

//single post
export const singlePostAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: SINGLE_POST_REQUEST });
  
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/post/getsinglepost/${id}`);
  
      dispatch({
        type: SINGLE_POST_SUCCESS,
        payload: data.UserPost,
      });
    } catch (error) {
      dispatch({
        type: SINGLE_POST_FAIL,
        payload: error.response.data.message,
      });
    }
  };
