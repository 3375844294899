import React, { useEffect, useState } from "react";
import Adminheader from "../Layout/Adminheader";
import Adminfooter from "../Layout/Adminfooter";
import { useDispatch, useSelector } from "react-redux";
import { resumeListAction } from "../../actions/resumeActions";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../Layout/loading/Loading";

const Resume = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [keyword, setKeyword] = useState("");

  //get resume
  const state = useSelector((state) => state.resumelist);
  const { resume, resultPerPage, resumeCount, loading } = state;

  const setCurrentPageNo = (e) => {
    //eslint-disable-next-line
    setCurrentPage(e);
  };

  const searchSubmitHandler = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    dispatch(resumeListAction(keyword, currentPage, from, to));
  };

  //fetching all resume information
  useEffect(() => {
    dispatch(resumeListAction(keyword, currentPage, from, to));
  }, [dispatch, keyword, currentPage, from, to]);

  return (
    <div>
      <div>
        <Adminheader />
        {/* HEADER */}
        <header id="main-header" className="py-2 bg-primary text-white">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1>
                  <i className="fas fa-folder" /> Resume
                </h1>
              </div>
            </div>
          </div>
        </header>
        {/* SEARCH */}
        <div>
          <section id="search" className="py-4 mb-4 bg-light">
            <form onSubmit={searchSubmitHandler}>
              <div className="container">
                <div className="row">
                  <div className="col-md-5 ml-auto mb-4">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Resume..."
                        onChange={(e) => setKeyword(e.target.value)}
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
        {/* Resume */}

        {loading ? (
          <Loading />
        ) : (
          <>
            <section id="categories">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="">
                      
                      {!resumeCount ? (
                        <>
                          <p>NO RESUME FOUND</p>
                        </>
                      ) : (
                        <>
                        <div className="card-header resume-header">
                        <div className="row">
                          <div className="col-md-4">
                            <h4>All Resume</h4>
                          </div>
                          <div className="col-md-4">
                            <div>
                              From
                              <DatePicker
                                name="from"
                                selected={from}
                                onChange={(date) => setFrom(date)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div>
                              To
                              <DatePicker
                                name="to"
                                selected={to}
                                onChange={(date) => setTo(date)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                          <div className="table-responsive">
                            <table className="table table-striped category-table">
                              <thead className="thead-dark">
                                <tr>
                                  <th>Full Name</th>
                                  <th>Last Name</th>
                                  <th>Email</th>
                                  <th>Uploaded At</th>
                                  <th>JOB Role</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {resume.map((items) => {
                                  return (
                                    <tr key={items._id}>
                                      <td>{items.fname}</td>
                                      <td>{items.lname}</td>
                                      <td>{items.email}</td>
                                      <td>
                                        {new Date(
                                          items && items.createdAt
                                        ).toDateString()}
                                      </td>
                                      <td>{items.jobRole}</td>
                                      <td>
                                        <a
                                          className="btn border border-success"
                                          rel="noreferrer"
                                          target="_blank"
                                          href={`${process.env.REACT_APP_BASE_URL}/${items.resume}`}
                                        >
                                          View
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </>
                      )}
                      {resultPerPage < resumeCount && (
                        <div className="paginationBox">
                          <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={resultPerPage}
                            totalItemsCount={resumeCount}
                            onChange={setCurrentPageNo}
                            nextPageText="Next"
                            prevPageText="Prev"
                            firstPageText="1st"
                            lastPageText="Last"
                            itemClass="page-item"
                            linkClass="page-link"
                            activeClass="pageItemActive"
                            activeLinkClass="pageLinkActive"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}

        {/* FOOTER */}
        <Adminfooter />
      </div>
    </div>
  );
};

export default Resume;
