import {
    LOGIN_REQUEST, 
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    CLEAR_ERRORS,
    USER_LOGOUT
} from "../constants/loginConstant";
import axios from "axios";
import { toast } from "react-toastify";

//login admin
export const login = (email,password) => async (dispatch)=>  {
    
    try{
        dispatch({type:LOGIN_REQUEST});
        const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/login`,{email,password})

        dispatch({
            type:LOGIN_SUCCESS,
            payload:data,
        });
        localStorage.setItem('blog-userInfo', JSON.stringify(data.savedUser))
        localStorage.setItem('blog-userToken', data.accessToken)
        toast.success("Login successfull")
    }catch(error){
       dispatch({
        type: LOGIN_FAIL,
        payload: error.response.data.message,
       });
       toast.error(error.response.data.message)
    }
};

  //admin logout
  export const logout = () => (dispatch) => {
    
    dispatch({ type: USER_LOGOUT })
    localStorage.removeItem('blog-userInfo')
    localStorage.removeItem('blog-userToken')
  }

//clear error
export const clearErrors = () => async (dispatch)=>  {
    dispatch({type:CLEAR_ERRORS});
};