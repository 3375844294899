import {
    LOGIN_REQUEST, 
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOGOUT,
    CLEAR_ERRORS
} from "../constants/loginConstant";

//admin login
export const loginReducer = (state={},action)=>{
    switch (action.type){

        case LOGIN_REQUEST:
            return{ 
                ...state,
                loading:true,
            };

        case LOGIN_SUCCESS:
            return{ 
                loading:false,
                    userInfo:action.payload.savedUser,
                    token:action.payload.accessToken,
            };
        case  LOGIN_FAIL:
            return{
                ...state,
                loading:false,
                user:null,
                error:action.payload,
            };
        case USER_LOGOUT:
            return {};
        case CLEAR_ERRORS:
            return{
                ...state,
                error:null,
            };
        default:
            return state;
    }
};

