import {
    ALL_POST_REQUEST,
    ALL_POST_SUCCESS,
    ALL_POST_FAIL,
    SINGLE_POST_REQUEST,
    SINGLE_POST_SUCCESS,
    SINGLE_POST_FAIL,
    CLEAR_ERRORS
} from "../constants/postConstant";

//post list reducer
export const allPostReducer = (state={ post:[]},action)=>{
    switch (action.type){
        case ALL_POST_REQUEST:
            return{
                loading: true,
                post:[]
            };
        case ALL_POST_SUCCESS:
            return{ 
                loading: false,
                post: action.payload.UserPost, 
                postCount: action.payload.postCount,
                resultPerPage: action.payload.resultPerPage,
                filterPostCount:action.payload.filterPostCount,
            };
        case ALL_POST_FAIL:
            return{
                loading: false,
                error: action.payload,  
            };
        case CLEAR_ERRORS:
            return{
                ...state,
                error:null,
            };
        default:
            return state;
    }
};

//single post
export const singlePostReducer = (state = { singleposts: {} }, action) => {
    switch (action.type) {
      case SINGLE_POST_REQUEST:
        return {
          loading: true,
          ...state,
        };
      case SINGLE_POST_SUCCESS:
        return {
          loading: false,
          singleposts: action.payload,
        };
      case SINGLE_POST_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };