import React, { useEffect, useState } from 'react';
import Adminheader from '../Layout/Adminheader';
import Adminfooter from '../Layout/Adminfooter';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Form } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import axios from 'axios';
import { toast } from 'react-toastify';
import { categoryListAction } from '../../actions/categoryAction';

const Postupdate = ({ ...props }) => {
  const token = localStorage.getItem("blog-userToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();

  const [detailPost, setdetailPost] = useState({
    title: "", status: "", description: "", category: "", SEOdescription: "", SEOtitle: "", SEOkeywords: "", photo: ""
  });
  const id = detailPost._id
  //eslint-disable-next-line
  const [file, setFile] = useState(null);
  //eslint-disable-next-line
  const [pdescription, setPDescription] = useState()
  const [categoryId, setCategoryId] = useState('')
  
  //update post
  async function Updatepost() {
    //eslint-disable-next-line
    const formData = new FormData();
    formData.append('title', detailPost.title)
    formData.append("status", detailPost.status);
    formData.append('description', pdescription)
    formData.append('category', categoryId)
    formData.append('SEOtitle', detailPost.SEOtitle)
    formData.append('SEOdescription', detailPost.SEOdescription)
    formData.append('SEOkeywords', detailPost.SEOkeywords)

    if (file) {
      formData.append('photo', file)
    }

    const productBody = formData;
    try {
      //eslint-disable-next-line
      const res = await axios.patch(`${process.env.REACT_APP_BASE_URL}/post/updatepost/${id}`, productBody, {
        headers:
        {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`
        }
      })
      navigate("/post")
      toast.success("Post Updated")
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }

  const handleCkeditorState = (event, editor) => {
    const data = editor.getData();
    setPDescription(data);
  };

  let names, values;
  const handleInputs = (e) => {
    e.preventDefault()
    names = e.target.name;
    values = e.target.value;
    setdetailPost({ ...detailPost, [names]: values })
  }


  //single post by id
  const getPost = async (slug) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/post/getsinglepost/${slug}`);
      setdetailPost(data.UserPost);
      setCategoryId(data?.UserPost?.category._id)
    } catch (error) {
      console.log(error)
    }
  };

  //get all categories
  const state = useSelector(state => state.categories)
  const { category } = state;

  const [categoryToDispaly, setCategoryToDispaly] = useState([])
  
  useEffect(() => {
    const arr = category.filter(e => e._id !== detailPost.category._id)
    setCategoryToDispaly(arr)
  }, [detailPost, category])

  useEffect(() => {
    getPost(slug)
    dispatch(categoryListAction())

    //eslint-disable-next-line
  }, [dispatch])

  //description image
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("image", file);
            fetch(`${process.env.REACT_APP_BASE_URL}/post/uploadimage`, {
              method: "post",
              body: body,
              headers: {
                Authorization: `Bearer ${token}`,
              }
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: `${process.env.REACT_APP_BASE_URL}/${res.filepath}`
                });
              })

              .catch((err) => {
                reject(err);
              });
          });
        });
      }
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }


  return (
    <div>
      <div>
        <Adminheader />
        {/* HEADER */}
        <header id="main-header" className="py-2 bg-primary text-white">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="mb-0">Post One</h2>
              </div>
            </div>
          </div>
        </header>
        {/* ACTIONS */}
        <section id="actions" className="py-4 mb-4 bg-light">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <NavLink to="/dashboard" className="btn btn-primary btn-block">
                  <i className="fas fa-arrow-left" /> Back To Dashboard
                </NavLink>
              </div>
            </div>
          </div>
        </section>
        {/* DETAILS */}
        <section id="details">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="card">
                  <div className="card-header bg-blue">
                    <h4 className="mb-0 text-white">Edit Post</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="title">Title</label>
                          <input
                            name="title"
                            value={detailPost.title}
                            onChange={handleInputs}
                            type="text"
                            className="form-control"
                            defaultValue="Post One"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="status">Status</label>
                          <Form.Select
                            className="form-control"
                            name="status"
                            onChange={handleInputs}
                          >
                            <option>{detailPost.status}</option>
                            <option value="Draft">Draft</option>
                            <option value="Publish">Publish</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="title">SEO Description</label>
                          <input
                            name="SEOdescription"
                            value={detailPost.SEOdescription}
                            onChange={handleInputs}
                            type="text"
                            className="form-control"
                            defaultValue="Post One"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="title">SEO Title</label>
                          <input
                            name="SEOtitle"
                            value={detailPost.SEOtitle}
                            onChange={handleInputs}
                            type="text"
                            className="form-control"
                            defaultValue="Post One"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="category">Category</label>
                          <Form.Select
                            className="form-control"
                            name="category"
                            onChange={(e) => setCategoryId(e.target.value)}
                          >
                            <option>{detailPost.category.name}</option>
                            {categoryToDispaly.map((data) => {
                              return (
                                <option key={data._id} value={data._id}>
                                  {data.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-group w-25">
                          <label htmlFor="image">Upload Image</label>
                          <div className="custom-file ">
                            <input
                              type="file"
                              name="photo"
                              onChange={(e) => setFile(e.target.files[0])}
                            />
                            <div className="mt-2 post-update-img">
                              <img
                                alt="loading"
                                src={`${process.env.REACT_APP_BASE_URL}/${detailPost.photo}`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 m-top">
                      <div className="form-group">
                        <label htmlFor="body">Description</label>
                        <CKEditor
                          config={{
                            extraPlugins: [uploadPlugin]
                          }}
                          editor={ClassicEditor}
                          onReady={(editor) => { }}
                          onBlur={(event, editor) => { }}
                          onFocus={(event, editor) => { }}
                          onChange={handleCkeditorState}
                          {...props}
                          data={detailPost.description}
                        />
                      </div>
                    </div>
                    <div className="">
                      <button
                        onClick={() => Updatepost(detailPost.slug)}
                        className="btn btn-success"
                        data-dismiss="modal"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FOOTER */}
        <Adminfooter />
      </div>
    </div>
  );
}

export default Postupdate